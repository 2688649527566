import React from 'react'
import NL from '../assets/imgs/nuevo_leon.png'
import Economia from '../assets/imgs/economia.png'
import { useAuth } from '../hooks/useAuth'
import { Link, NavLink } from 'react-router-dom'
export const Navbar = () => {
    const {logout, user}=useAuth()


  return (
    <nav>
    <div className="nav-links">
        <ul>

            <li><NavLink    target={'_blank'} to="https://www.nl.gob.mx/"><img src={NL} alt="Estado de Nuevo Leon" /></NavLink>  </li>
            <li><NavLink   to="/">Panel</NavLink> </li>
            <li><NavLink  to="/formulario">Generar solicitud</NavLink> </li>
            {
              user.rol ===1 ?
              <li><NavLink  to="/contacto">Contacto</NavLink> </li>
              :
              <li><NavLink  to="/reportes">Reportes</NavLink> </li>
            }
            <li><button onClick={logout} className="btn btn-secundary">Cerrar sesion</button> </li>
            <li><NavLink  target={'_blank'} to="https://www.nl.gob.mx/economia"><img src={Economia} alt="Economia Nuevo Leon- Gabinete de generacion de riqueza sostenible"/> </NavLink>  </li>
        </ul>
    </div>
    
        <hr />
    </nav>
  )
}
