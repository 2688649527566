import React, { useEffect, useState, useRef } from 'react'
import { Navbar } from '../components/Navbar'
import '../assets/css/dates.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import getDay from "date-fns/getDay"
import 'react-datepicker/dist/react-datepicker.css'
import { useAuth } from '../hooks/useAuth'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import moment from 'moment';
import dateApi from '../api/dateApi'
import Swal from 'sweetalert2';
import meet from '../assets/imgs/meet.png'



export const Dates = () => {

    const {id_form} = useParams()
    const [mode, setMode] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(setHours(setMinutes(new Date(), 11), 11));
    const LUNCH_TIME  = ["13:00", '13:30']
    const [blockedHours, setBlockedHours] = useState(LUNCH_TIME);
    const [blockedDates, setBlockedDates] = useState([]);
    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
      };

    const getDisableDates =async ()=>{
        const dateFormated = moment(selectedDate).format('DD/MM/YYYY HH:mm')
        const date = dateFormated.split(' ')[0]
        const time = dateFormated.split(' ')[1]

        const {data:hours } = await dateApi.post('/disable/hours',{
              date
          })
        const {data:dates } =await dateApi.get('/disable/dates' )

        setBlockedDates([...dates])
        setBlockedHours( [...LUNCH_TIME, ...hours])
    }
    useEffect(() => {
        getDisableDates()
    }, [selectedDate])
    

    const handleVerifyDate= async(e)=>{
        e.preventDefault();
        const dateFormated = moment(selectedDate).format('DD/MM/YYYY HH:mm');
        const date = dateFormated.split(' ')[0]
        const time = dateFormated.split(' ')[1]
        if (!date) {
            setMsg('Seleccione una fecha de reunión')
            return
        }
        if (!time || time ==='11:11') {
            setMsg('Seleccione una hora de reunión')
            return
        }
        if (!mode) {
            setMsg('Seleccione una modalidad de reunión')
            return
        }
        //Verificar fecha y hora 
        
        if (blockedDates.includes(date) || blockedHours.includes(time)) {
            console.log("incluye")
            setMsg('La hora o fecha seleccionada no se encuentra disponible')
            return
        }
        setMsg('')

        const {data }= await dateApi.post('add/'+id_form, {
            date,
            time,
            mode
        })
        console.log(data)
        if (data && !data.error) {
            Swal.fire({
                title: "Datos agregados correctamente",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
            }).then(()=>{
                navigate(`/`)
                    

            })
        }

        console.log(moment(selectedDate).format('DD/MM/YYYY HH:mm'))
    }

    const { validateForm} = useAuth()
    const navigate= useNavigate()

    const isValidForm = async()=>{
        const isValid = await validateForm(id_form)
        if (!isValid) {
            navigate('/')
        }
    }
    useEffect(() => {
        isValidForm()
    }, [])

    useEffect(() => {
        // Lógica para bloquear las horas según el día seleccionado
        const dayOfWeek = moment(selectedDate).format('dddd');
        if (dayOfWeek === 'Monday') {
          setBlockedHours( [...LUNCH_TIME,  "10:30", 12, 13]);
        } else if (dayOfWeek === 'Tuesday') {
          setBlockedHours([...LUNCH_TIME,13, 14]);
        } else if (dayOfWeek === 'Wednesday') {
          setBlockedHours([...LUNCH_TIME,12, 14, 15]);
        } else if (dayOfWeek === 'Thursday') {
          setBlockedHours([...LUNCH_TIME,11, 16, 17]);
        } else {
          setBlockedHours([...LUNCH_TIME]);
        }
      }, [selectedDate]);


  return (
    <>
    <Navbar />
    <div className="dates-container">
        <div className="form-header">
            <div className="form-title">
                Agendar cita 
            </div>
            <div className="form-description">
                Horarios de atencion: Lunes a Viernes de 9:00 hrs a 17:00 hrs 
            </div>
        </div>
        <div className="info" data-form="<%= id_form %>"></div>
        <form  className="dates-form" method="post" >
            <div className="inputfield">
                <label htmlFor="datepicker"><i className="far fa-calendar"></i> Selecciona una fecha y hora en el calendario</label>
                    {/* <input type="text" id="datepicker" name="date" placeholder="<%= date? date.date.trim() : "dd/mm/aaaa" %> " value="<%= date? date.date.trim() : "" %> " required /> */}
                    {/* <input type="text" id="datepicker" name="date" placeholder="dd/mm/aaaa"  required /> */}
                    <DatePicker
                        inline
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        filterDate={isWeekday}
                        dateFormat="dd/MM/yyyy h:mm"
                        minDate={new Date()}
                        //maxDate={moment().add(1, 'month').toDate()}
                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 30), 16)}
                        timeCaption="Time"
                        excludeDates={blockedDates.map(date => 
                            moment(date, 'DD/MM/YYYY').toDate())
                        }
                        excludeTimes={blockedHours.map((hour) =>
                            moment(selectedDate)
                                .startOf('day')
                                .add(hour, 'hours')
                                .toDate()
                        )}
                    />
                
            </div>
            <div className="inputfield">
                <label htmlFor=""> Selecciona la modalidad de la cita</label>
                <div className="modes">
                    <div onClick={()=>setMode('in-person')} className={`${mode==='in-person'? "selected": "" } mode in-person`}>
                        <i className="fa-regular fa-handshake"></i>
                        <span>Presencial</span>
                    </div>
                    <div onClick={()=>setMode('virtual')}  className={`${mode==='virtual'? "selected": "" } mode virtual`}>
                        <img src={meet} alt="" />
                        <span>Virtual</span>
                    </div>
                </div>
            </div>
            {
                msg
            }
            <div className="inputfield botones">
                <button type="button" className="btn btn-primary sin-cita" style={{background:'var(--oro)'}} > Sin cita </button>
               
                <a className="btn btn-secundary" href="/dashboard"> Agendar en otro momento </a>
                <button onClick={handleVerifyDate} className="btn btn-primary"> Agendar </button>
            </div>
                
                {/* <button type="button" className="btn btn-secundary cancel-date" data-path="/dates/delete/<%= id_form %>">
                    Cancelar cita
                </button> */}


        </form>

        
        
        
    </div>
    </>
  )
}
