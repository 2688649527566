import React, { useState } from 'react'
import { Navbar } from '../components/Navbar'
import '../assets/css/dashboard-admin.css'
import cedulasApi from '../api/cedulasApi'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const HomeAdmin = () => {
  const { validateSession} = useAuth()
  validateSession()
  const [search, setSearch] = useState('')
  const [msgSearch, setMsgSearch] = useState('')
  const navigate= useNavigate()

  const searchCedula = async(e)=> {
    e.preventDefault()
    if (!search) {
        setMsgSearch("Ingrese un RFC o Cédula Valida")
        return
    }
    try {
      const {data} = await cedulasApi.get('/'+search)
      if (data && !data.error) {
        console.log(data)
        setMsgSearch('')
        navigate('/admin/'+data.cedula, {state:data})
      }else{
        setMsgSearch(data.msg || "Error al buscar la solicitud")
      }
    } catch (error) {
      
    }
  }


  return (
   <>
    <Navbar />
    <div className="dashboard-container container">
        <div className="dashboard-name">
            <h3>PANEL DE ADMINISTRACIÓN</h3> 
        </div>
        <div className="dashboard-cards">
            <div className="card-container">
                <div className="card-container-txt">
                    <h2>
                        Buscar registro
                    </h2>
                    <p>Ingresa numero de cedula o RFC</p>
                </div>
                <div className="search-date-form">
                    <form className="form-field search-date">
                        <input value={search} onChange={(e)=>setSearch(e.target.value)} type="text" id="cedula" name="cedula" />
                        <button onClick={searchCedula} className="btn btn-primary"> <i className="fas fa-search"></i> Buscar</button>
                    </form>
                    <span>{msgSearch}</span>
                </div>
            </div>
        </div>
    </div>
   </>
  )
}
