import { Navbar } from '../components/Navbar'
import { useAuth } from '../hooks/useAuth'
import '../assets/css/dashboard.css'
import cedulasApi from '../api/cedulasApi'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const Home = () => {
  const {user, validateSession} = useAuth()
  const [cedulas, setCedulas] = useState([])
  const [filtered, setFiltered] = useState([])
  validateSession()

  const getCedulas = async()=>{
    const {data} = await cedulasApi.get('/');
    console.log(data)
    if (data && !data.error) {
      setCedulas(data.cedulas)
      setFiltered(data.cedulas)
    }
  }
  useEffect(() => {
    getCedulas()
  }, [])

  const showTarget = (e)=> {
    const btnActions = document.querySelectorAll('.btn-actions');
    btnActions.forEach(btn => btn.classList.remove('btn-active'));
    e.target.classList.add('btn-active');
    const target= e.target.dataset.target; 
    const notification = e.target.querySelector('.notification')
    if (notification)notification.classList.add('hide')
    if (target === 'completed-cards') {
      getCedulas()
    }else if (target === 'nodate-cards') {
      const filteredCedulas = cedulas.filter(cedula => cedula.no_date == 1 );
      setFiltered(filteredCedulas)
    }else if (target === 'pendings-cards') {
      const filteredCedulas = cedulas.filter(cedula => cedula.id_date === null && cedula.id_file  );
      setFiltered(filteredCedulas)
    }else if (target === 'pendings-files-cards') {
      const filteredCedulas = cedulas.filter(cedula => cedula.id_file === null  );
      setFiltered(filteredCedulas)
    }


  }

  
  

  return (
    <>
      <Navbar />
      <div className="dashboard-container container">
        <div className="dashboard-name">
            <h2>Hola, </h2> <h3>{user.name}</h3> 
        </div>
      </div>
      {
        cedulas.length ? 
        <div className="dashboard-actions">
                <div className="btn-container">
                    <button onClick={showTarget} data-target="completed-cards" className="btn btn-primary btn-actions btn-active">Mis Solicitudes
                    </button>
                </div>
                        {
                        cedulas.some(date => date['no_date'] === 1 && !date.completed) && 
                        <div className="btn-container">
                            <button onClick={showTarget} data-target="nodate-cards" className="btn btn-primary btn-actions ">Solicitudes SIN CITA</button>
                                  <div className="notification"></div>
                        </div>
                        }
                        {
                          cedulas.some(date => date['date.id_date'] === null && date['file.id_file'] !== null && date['no_date'] !== 1  && !date.completed )  && 
                        <div className="btn-container">
                            <button onClick={showTarget}  data-target="pendings-cards" className="btn btn-primary btn-actions">Pendientes de cita
                                  <div className="notification"></div>
                            </button>
                        </div>
                        }
                        {
                          cedulas.some(file => file.id_file === null) && 
                        <div className="btn-container">
                            <button onClick={showTarget}  data-target="pendings-files-cards" className="btn btn-primary btn-actions">Pendientes de archivos
                                  <div className="notification"></div>
                            </button>
                        </div>
                        }
               
                
            </div>
            :
            <></>
      }
      {
        //renderTarget(target)

      }
      <div className="dashboard-cards completed-cards">
          {
            filtered.length? 
              filtered.map(date => (
                <div key={date.cedula} className="date-card">
                  <div className="process">
                    <div className={`process-form step ${date.id_form? 'completed':""}`}>
                      <i className="fa-regular fa-file-lines"></i>
                      <span>Cuestionario</span>
                    </div>
                    <div className={`process-file step ${date.id_file? 'completed':""}`}>
                      <i className="fa-regular fa-folder-open"></i>
                      <span>Documentos</span>
                    </div>
                    <div className={`process-date step ${date.id_date? 'completed':""}`}>
                      <i className="fa-regular fa-calendar-check"></i>
                      <span>Cita</span>
                    </div>
                    <div className={`process-date step ${date.completed && date.approved ===1 ? 'completed': date.completed && date.approved ===0? 'declinada' : ""}`}>
                      <i className={`fa-solid ${!date.completed?'fa-circle-question': date.completed && date.approved ===1? 'fa-circle-check' : 'fa-circle-xmark'} `}></i>
                      {
                        (date.approved === 0 || date.approved === 1)?
                        <>
                        <span className="status"> {date.approved === 1? "Aprobada" : "Declinada" } </span>
                        </>
                        :
                        <span className="status"> Sin evaluar </span>
                      }
                    </div>
                  </div>
                    <p className="cedula">{date.cedula }</p>
                    <div className="date-card-text">
                        <h3>{date['form.NOMBRE_EMPRESA'] }</h3>  
                    </div>
                    {
                      date['id_date'] && date['date.mode'] == 'virtual' ?
                      <>
                        <div className="date-card-text">
                            <h3>Día:</h3> <p> {date['date.date']} </p> 
                            <h3>Hora:</h3> <p> {date['date.time'] } </p> 
                        </div>
                        <div className="date-card-text">
                            <h3>Liga de la reunión:</h3> <p> <a href="" target={'_blank'}>GOOGLE MEET</a> </p> 

                        </div>
                      </>
                      :
                      date['id_date'] && date['date.mode'] == 'in-person' ?
                      <>
                        <div className="date-card-text">
                            <h3>Día:</h3> <p> {date['date.date']} </p> 
                            <h3>Hora:</h3> <p> {date['date.time'] } </p> 
                        </div>
                      </>
                      :
                      date.no_date == 1 ?
                      <>
                        <div className="date-card-text">
                            <h3>Solicitud sin cita</h3> 
                            <p> La generación de la solicitud sin cita no implica la autorización de la misma. En esta opción el trámite deberá ser finalizado por medio de la institución bancaria de su preferencia o bien en módulos especiales (eventos y campañas organizadas con los municipios o cámaras empresariales) </p> 
                            <span>Si desea cambiar de modalidad, clic <Link to={ `/cita/${date.id_form}` } className="btn btn-primary">aquí</Link> o pongase en contacto con nostoros </span>
                        </div>
                      </>
                      :
                      ""
                    }
                      <div className="actions">
                      {
                        !date.id_file ?
                          <Link to={ `/archivos/${date.id_form}` } className="btn btn-primary">Agregar documentos</Link>
                          :
                          !date.id_date && !date.completed? 
                          <Link to={ `/cita/${date.id_form}` } className="btn btn-primary">Agendar cita</Link>
                          :
                          <Link to={ `/cita/info/${date.id_form}` } className="btn btn-primary">Consultar cita</Link>
                          
                        
                      }
                    </div>
                </div>
              ))
            :
            <div className="date-card">
                <h3>Generar nueva solicitud</h3>
                <Link to="formulario" className="btn btn-primary">Generar</Link>
            </div>
          }
    </div>
    </>
  )
}
