import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth.js'
import { DateAdmin } from '../pages/DateAdmin.jsx';
import { DateInfo } from '../pages/DateInfo.jsx';
import {  Dates } from '../pages/Dates.jsx';
import { Files } from '../pages/Files.jsx';
import { Form } from '../pages/Form';
import { Home } from '../pages/Home';
import { HomeAdmin } from '../pages/HomeAdmin.jsx';
import { Login } from '../pages/Login';
import { RecoverPassword } from '../pages/RecoverPassword';
import { Reportes } from '../pages/Reportes.jsx';

type Props = {
  children: JSX.Element,
};

const ProtectedRoute = ({ children }:Props) =>{
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    if (!isAuthenticated){
        return <Navigate to={'/acceso'} state={{location}} />
    }
    return children
}




export const AppRoute = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Routes >
        <Route path="acceso/:token?" element={ isAuthenticated ?  <Home/> : <Login /> } />
        <Route path="acceso" element={ isAuthenticated ?  <Home/> : <Login /> } />
        <Route path="contraseña" element={ isAuthenticated ?  <Home/> : <RecoverPassword /> } />
        <Route path="/" element={  user.rol ==='0'?<ProtectedRoute><Home /></ProtectedRoute> : <ProtectedRoute><HomeAdmin/></ProtectedRoute>  }></Route>
        <Route path="/formulario" element={ <ProtectedRoute><Form /></ProtectedRoute>}></Route>
        <Route path="/reportes" element={ user.rol ==='0'?<ProtectedRoute><Home /></ProtectedRoute> : <ProtectedRoute><Reportes/></ProtectedRoute> }></Route>
        <Route path="/archivos/:id_form" element={ <ProtectedRoute><Files /></ProtectedRoute>}></Route>
        <Route path="/cita/:id_form" element={ <ProtectedRoute><Dates /></ProtectedRoute>}></Route>
        <Route path="/cita/info/:cedula" element={ <ProtectedRoute><DateInfo /></ProtectedRoute>}></Route>
        <Route path="/admin/:cedula" element={  user.rol ==='0'?<ProtectedRoute><Home /></ProtectedRoute> : <ProtectedRoute><DateAdmin/></ProtectedRoute>  }></Route>
          
    </Routes>
  )
}
