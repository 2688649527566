import { Header } from '../components/Header'
import Swal from 'sweetalert2'

import '../assets/css/auth.css'
import { useAuth } from '../hooks/useAuth';
import LogoImpulso from '../assets/imgs/logotipo.png'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import authApi from '../api/authApi';
import { AxiosError } from 'axios';

type LoginValues = {
    email: string;
    password: string;
};
type RegisterValues = {
    name: string;
    email: string;
    password: string;
    tac:boolean
};
type ErrorsType = {
    location: string;
    msg: string;
    param: string;
};

export const Login = () => {
    const [showRegister, setShowRegister] = useState<boolean>(false)
    const [loginForm, setLoginForm] = useState<LoginValues>({email:"", password:""})
    const [msg, setMsg] = useState("")
    const [registerForm, setRegisterForm] = useState<RegisterValues>({email:"",name:"", password:"", tac:false})
    const {login, register} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const {token }= useParams()

    const activeToken = async(token:string)=> {
        try {
            const {data}=await authApi.post('/'+token)
            if (data && !data.error) {
                Swal.fire("Listo", data.msg, 'success')
            }
        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                return err.response.data
            }else{
                return {
                    error: true,
                    msg: "Servidor desconectado"
                }
            }
        }
    }
    useEffect(() => {
        if (!token) return

        activeToken(token)
    }, [token])
    


    const handleChangeLogin = (e: { target: HTMLInputElement; })=> {
        setLoginForm({ ...loginForm, [e.target.name]: e.target.value})
    }
    const handleLoginSubmit = async(e: React.FormEvent<HTMLFormElement>)=> {
        
        e.preventDefault(); 
        if (loginForm.email && loginForm.password) {

            const res = await login(loginForm.email, loginForm.password )
            console.log(res)
            if (res &&  !res.error) {
              if (location.state) {
                navigate(`${location.state.location.pathname }`)
              }else{
                navigate('/')
              }
              
            }else{ 
                if (res.errors){
                    const errors = res.errors.map((error:ErrorsType) => <li>error.msg</li>)
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        html: `<ul style=" list-style: none; ">${errors}</ul>`
                    })
                }else{
                    Swal.fire("Error", res.msg, 'error')
                    setMsg(res.msg);
                }
            }
            
          }else{
            setMsg('Faltan campos por completar')
          }
    }
    const handleChangeRegister = (e: { target: HTMLInputElement; })=> {
        if(e.target.type === 'checkbox')
            setRegisterForm({ ...registerForm, [e.target.name]: e.target.checked})
        else
            setRegisterForm({ ...registerForm, [e.target.name]: e.target.value})
    }
    const handleRegisterSubmit = async(e: React.FormEvent<HTMLFormElement>)=> {
        console.log(registerForm)
        e.preventDefault(); 
        if(registerForm.tac === false)
            setMsg('Es necesario aceptar los terminos y condiciones para continuar')
        if (registerForm.email && registerForm.password) {

            const res = await register(registerForm.email, registerForm.password, registerForm.name )
            if (res &&  !res.error) {
                Swal.fire("Listo", res.msg, 'success')
                setShowRegister(false)
              
            }else{ 
                if (res.errors){
                    const errors = res.errors.map((error:ErrorsType) => `<li>${error.msg}</li>`)
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        html: `<ul style=" list-style: none; ">${errors}</ul>`
                    })
                }else{
                    Swal.fire("Error", res.msg, 'error')
                    setMsg(res.msg);
                }
            }
            
          }else{
            setMsg('Faltan campos por completar')
          }
    }

  return (
    <>
      <Header />
      <div className="auth-container container">
        <main >
            <div className="left">
                <div className="logo">
                    <img src={LogoImpulso} alt="IMPULSO" />
                </div>
                <div className="form-action">
                  {
                    showRegister ?
                    <>
                     <h1>REGISTRO</h1>
                      <div className="form-field">
                              <button onClick={()=>setShowRegister(false)} className="btn-secundary btn reset-btn" ><u>Iniciar sesión</u> </button>
                      </div>
                    </>
                    :
                    <>
                      <h1>INICIAR SESION</h1>
                      <div className="form-field">
                              <button onClick={()=>setShowRegister(true)} className="btn-secundary btn reset-btn" >No tengo una cuenta, <u>registrarme</u> </button>
                      </div>
                    </>

                  }
                    
                </div>
                
    
            </div>
            <div className="right">
                <div className="form-container">
                    {
                        !showRegister ? 
                        <form className="login-form" onSubmit={handleLoginSubmit}>
                            <div className="form-field">
                                <label htmlFor="login-email">Correo</label>
                                <input type="email" name="email" id="login-email" value={loginForm.email} onChange={handleChangeLogin} required />
                            </div>
                            <div className="form-field">
                                <label htmlFor="login-password">Contraseña</label>
                                <input type="password" name="password" id="login-password" value={loginForm.password} onChange={handleChangeLogin} required />
                            </div>
                            <div className="form-field">
                                <Link to={'/contraseña'} state={{email:loginForm.email}} type='button' className="btn-secundary btn reset-btn" >¿Olvidaste tu contraseña?</Link>
                            </div>
                                <button className="btn btn-primary" type="submit">Entrar</button>
                        </form>
                        :
                        <form className="sign-up-form" onSubmit={handleRegisterSubmit} >
                            <div className="form-field">
                                <label htmlFor="sign-up-name">Nombre</label>
                                <input type="text" name="name" id="sign-up-name" value={registerForm.name} onChange={handleChangeRegister} required />
                            </div>
                            <div className="form-field">
                                <label htmlFor="sign-up-email">Correo</label>
                                <input type="email" name="email" id="sign-up-email" value={registerForm.email} onChange={handleChangeRegister} required />
                            </div>
                            <div className="form-field">
                                <label htmlFor="sign-up-password">Contraseña</label>
                                <input type="password" name="password" id="sign-up-password" value={registerForm.password} onChange={handleChangeRegister} required />
                            </div>
                            <div className="form-field-line btn btn-secundary">
                                <input type="checkbox" name="tac" id="tac"  onChange={handleChangeRegister} checked={registerForm.tac} required />
                                <label className="tacBtn" htmlFor="tac">Acepto terminos y condiciones</label>
                            </div>
                                {msg}
                                <button className="btn btn-primary" type="submit">Registrarse</button>
                        </form>
                    }
                    
                    <form className="reset-form hide" method="post" action="/auth/reset">
                        
                        <div className="form-field">
                            <h2 >Ingrese el correo con el se registró</h2>
                        </div>
                        <div className="form-field">
                            <label htmlFor="reset-email">Correo</label>
                            <input type="email" name="email" id="reset-email" data-oninvalid="this.setCustomValidity('Ingrese un correo válido')"  required />
                        </div>
                            <button className="btn btn-primary" type="submit">Enviar</button>
                    </form>
                </div>
    
    
            </div>
            

        </main>

        <section className="search-date">
            <div className="search-date-title">
                <h3>Buscar cita</h3>
                <form action="/dates/cedula" method="post" className="form-field search-date">
                    <label htmlFor="id_date">Cedula</label>
                    <input type="text" name="id_date" id="id_date" required  data-oninvalid="this.setCustomValidity('Ingrese un formato válido')"   className="registro" />
                    <button className="btn btn-primary">
                        <i className="fas fa-search"></i>
                    </button>
                </form>
            </div>
        </section>


    </div>


    </>
  )
}
