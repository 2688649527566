import React, { useEffect, useState } from 'react'
import { Navbar } from '../components/Navbar'
import '../assets/css/files.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import filesApi from '../api/filesApi'
import { useAuth } from '../hooks/useAuth'
import { Loading } from '../components/Loading'
import Swal from 'sweetalert2'

export const Files = () => {
    const {id_form} = useParams()
    const [files, setFiles] = useState({})
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);


    const { validateForm} = useAuth()
    const navigate= useNavigate()

    const isValidForm = async()=>{
        const isValid = await validateForm(id_form)
        if (!isValid) {
            navigate('/')
        }
    }
    useEffect(() => {
        isValidForm()
       
    }, [])
    
    
    


    const handleUpdateFile = (e)=>{
        const name = e.target.name;
        const size = document.querySelector(`.err-${name} .file_size`)
        const format = document.querySelector(`.err-${name} .file_format`)
        const file = e.target.files[0]
        if (file) {
            if (file.size/1048576 > (e.target.dataset.max )) {
                size.style.color = "red";
            }else {
                size.style.color = "green";
            }
            if ( !format.textContent.toLowerCase().includes(file.type.split("/")[1].toLowerCase()) ){
                format.style.color = "red";
            }else {
                format.style.color = "green";
            }
    
            if (format.style.color === "green" && size.style.color === "green") {
                e.target.dataset.before = file.name
                setFiles({...files, [e.target.name]:file})
                setError('')
            }else{
                e.target.dataset.before = 'Seleccionar un archivo válido'
                setError('Revise el tamaño y formato de sus archivos')
            }
        }
    }

    const uploadFiles =async(e)=>{
        e.preventDefault()
        setLoading(true);

        if (!error && Object.keys(files).length >= 4) {
            const formData = new FormData();
            Object.keys(files).forEach(function(key) {
                formData.append(key, files[key])
            });
            try {
                const {data} = await filesApi.post('/'+id_form, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (data && !data.error) {
                    Swal.fire({
                        title: "Archivos agregados correctamente",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        hideClass: {
                          popup: 'animate__animated animate__fadeOutUp'
                        }
                    }).then(()=>{
                        navigate(`/cita/${id_form}`)
                    })
                    
                }
            } catch (error) {
                console.log(error)
                Swal.fire({
                    title: "Error",
                    icon: 'error',
                    text: error.response?.data.msg || "Error en servidor",
                    showConfirmButton: false,
                    timer: 2000
                }).then(()=>{
                    setTimeout(() => {
                        navigate(`/`)
                        
                    }, 1000)
                })
            }
            
            setLoading(false);
        }else {
            setError('Revise sus archivos antes de enviar')
        }

    }


  return (
    <>
    <Navbar />
    <div className="files-container container">
        <div className="form-header">
            <div className="form-title">
                Archivos 
            </div>
            <div className="form-description">
                <ul>
                    <li>Comprobante de domicilio</li>
                    <li>Acta constitutiva o poder</li>
                    <li>ID Representante legal</li>
                    <li>Constancia de situacion fiscal</li>
                </ul>
            </div>
        </div>
        <div className="files-form" >
            <form method="post" >
            <div className="inputfield input-file">
                    <label className="title" htmlFor="comprobante_domicilio"> Comprobante de domicilio</label>
                    
                    <input onChange={handleUpdateFile} data-max="2" title=" "  data-before='Seleccionar un archivo' className="file-input" type="file" id="comprobante_domicilio" name="comprobante_domicilio" accept=".pdf"  required />
                    <ul className='err-comprobante_domicilio'>
                        <li>No mayor a 3 meses de antigüedad</li>
                        <li className="file_format">Formato PDF</li>
                        <li className="file_size">Tamaño max. 2mb</li>
                    </ul>
            </div>
            <div className="inputfield input-file">
                    <label className="title" htmlFor="acta_constitutiva"> Acta constitutiva  </label>
                    <input onChange={handleUpdateFile}  data-max="10" title=" " data-before='Seleccionar un archivo' className="file-input" type="file" id="acta_constitutiva" name="acta_constitutiva"  accept=".pdf"   />
                    <ul className='err-acta_constitutiva'>
                        <li className="file_format">Formato PDF</li>
                        <li className="file_size">Tamaño max. 10mb</li>
                    </ul>
                    
            </div>
            <div className="inputfield input-file">
                    <label className="title" htmlFor="poder_notarial">Poder notarial  </label>
                    <input onChange={handleUpdateFile}  data-max="10" title=" " data-before='Seleccionar un archivo' className="file-input" type="file" id="poder_notarial" name="poder_notarial"  accept=".pdf" />
                    <ul className='err-poder_notarial'>
                        <li className="file_format">Formato PDF</li>
                        <li className="file_size">Tamaño max. 10mb</li>
                    </ul>
                    
            </div>
            <div className="inputfield input-file">
                    <label className="title" htmlFor="ID_representante"> ID Representante legal</label>
                    <input onChange={handleUpdateFile}  data-max="2" title=" " data-before='Seleccionar un archivo' className="file-input" type="file" id="ID_representante" name="ID_representante"  required accept="image/*,.pdf"   />
                    <ul className='err-ID_representante'>
                        <li className="file_format">Formatos PDF, jpg o jpeg</li>
                        <li className="file_size">Tamaño max. 2mb</li>
                    </ul>
            </div>
            <div className="inputfield input-file">
                    <label className="title" htmlFor="constancia_situacion_fiscal"> Constancia de situacion fiscal</label>
                    <input onChange={handleUpdateFile} data-max="2" title=" " data-before='Seleccionar un archivo'  className="file-input" type="file" id="constancia_situacion_fiscal" name="constancia_situacion_fiscal" accept=".pdf" required />
                    <ul className='err-constancia_situacion_fiscal'>
                        <li className="file_format">Formato PDF</li>
                        <li className="file_size">Tamaño max. 2mb</li>
                    </ul>
            </div>
                <div className="inputfield botones">
                    <Link className="btn btn-secundary" to="/"> Agregar en otro momento </Link>
                    {
                        loading ?
                        <Loading /> :
                        <button onClick={uploadFiles} className="btn btn-primary" type="submit"> Continuar </button>
                    }
                {
                    error 
                    
                }
                
                </div>
            </form>
            
        </div>
        
        
        
        
    </div>
    </>
  )
}
