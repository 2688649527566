import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'; 
import NL from '../assets/imgs/nuevo_leon.png'
import Economia from '../assets/imgs/economia.png'
import LogoImpulso from '../assets/imgs/logotipo.jpg'
export const DateReport = ({data }) => {

    Font.register({
        family: "Roboto",
        fonts: [
            { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf", fontWeight: 300 },
            { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf", fontWeight: 400 },
            { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf", fontWeight: 500 },
            { src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf", fontWeight: 600 },
          ],
      });
    
      
      
      const styles = StyleSheet.create({
        page: {
          fontFamily: "Roboto",
          fontSize: 11,
        },
        report_html: {
          padding: 20,
        },
        report_header: {
          textAlign: 'center',
        },
        logos: {
            display: 'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width:'100%'
        },
        logo: {
          maxWidth: 100,
          maxHeight: 110,
        },
        detailsTitle:{
            marginVertical:20,
            textAlign:'center'
        },
        detailsContainer: {
            paddingVertical: '16',
            paddingHorizontal: '10',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        recommendationsList: {
            listStyleType: 'none',
            padding: 0,
            flexDirection: 'column',
        },
        recommendationsListItem: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        recommendationsListItemIcon: {
            marginRight: 5,
        },
        detailsHeader: {
            marginVertical:20,
            
        },
        date: {
            alignItems: 'flex-start',
          borderLeftWidth: 1,
          borderLeftColor: 'black',
          paddingLeft: 20,
          justifyContent: 'start'
        },
        place: {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginVertical: 30,
        },
        placeAddress: {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginVertical: 30,
        },
        actions: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginVertical: 20,
        },
        status: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginVertical: 20,
        },
        statusInfo: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        statusDot: {
          width: 10,
          height: 10,
          borderRadius: 5,
          marginRight: 5,
        },
        statusDotRed: {
          backgroundColor: 'red',
        },
        statusDotGreen: {
          backgroundColor: 'green',
        },
      });
      

  
     

  
 
    

  return (
    <Document>
    <Page size="LETTER" style={styles.page}>
      
    <View style={styles.report_html}>
            
        <View style={styles.detailsContainer}>
            <View style={styles.logos}>
                <Image source={NL} style={styles.logo} />
                <Image source={LogoImpulso} style={styles.logo} />
                <Image source={Economia} style={[styles.logo, { width: 120 }]} />
            </View>
            <View style={styles.detailsTitle}>
                <Text style={[styles.title, { color: "#009FE3", textAlign: "center" }]}>CONFIRMACIÓN DE CITA PRESENCIAL</Text>
                <Text style={styles.title}>PROGRAMA IMPULSO NUEVO LEÓN 2023</Text>
            </View>
            <View style={[styles.detailsHeader]}>
                <View style={styles.date}>
                    <Text style={styles.dateText}>Fecha: {data["date.date"]}</Text>
                    <Text style={styles.dateText}>Hora: {data["date.time"]}</Text>
                </View>
            </View>
            <View style={styles.form}>
                <Text style={styles.formText}>
                    Cedula:{" "}
                    <Text style={{ color: "red" }}>{data.cedula}</Text>
                </Text>
                <Text style={styles.formText}>Empresa / Solicitante: {data["form.NOMBRE_EMPRESA"]}</Text>
                <Text style={styles.formText}>Representante legal: {data["form.REPRESENTANTE_LEGAL"]}</Text>
                <Text style={styles.formText}>{data["form.CORREO_ELECTRÓNICO"]}</Text>
                <Text style={styles.formText}>RFC: {data["form.RFC"]}</Text>
            </View>
            <View style={styles.place}>
                <View style={styles.placeAddress}>
                    <Text style={styles.placeTitle}>Lugar de la cita:</Text>
                    <Text style={styles.placeAddressText}>
                        Pabellón Ciudadano, Tercer Piso.
                        {"\n"}
                        Washington 2000 Ote. C.P. 64010,
                        {"\n"}
                        Col. Obrera, Monterrey, N. L. .-
                    </Text>
                </View>
                <View style={styles.recomendations}>
                    <Text style={styles.recomendationsTitle}>Recomendaciones:</Text>
                    <Text style={styles.recomendationsText}>
                        - El trámite es personal y debe ser realizado por apoderado o representante legal de la empresa{"\n"}
                        - Acudir a la cita con Identificación oficial vigente y la confirmación de cita.
                    </Text>
                </View>
            </View>
        </View>
    </View>
    </Page>
  </Document>

    


  )
}
