 export const questionnaire =  [
        {
            id: 1,
            title: "I.- DATOS GENERALES",
            description: "INSTRUCCIONES DE LLENADO: TODA LA INFORMACION DEBERA LLENARSE EN MAYUSCULAS SIN ACENTOS Y DEBERA TOMARSE DE INFORMACION DE SOPORTE OFICIAL (EJEMPLO: CONSTANCIA DE SITUACIÓN FISCAL)",
            questions: [
                {
                    idQ: 1,
                    type: "text",
                    text: "NOMBRE Ó RAZÓN SOCIAL DE LA EMPRESA",
                    name: "NOMBRE_EMPRESA",
                    autocomplete : "organization"
                },
                {
                    idQ: 2,
                    type: "text",
                    text: "NOMBRE COMPLETO DEL REPRESENTANTE LEGAL",
                    optional: true,
                    name: "REPRESENTANTE_LEGAL",
                    description:'En caso de no ser persona moral dejar en blanco',
                    autocomplete : "name"
                },
                {
                    idQ: 3,
                    type: "radio",
                    text: "GÉNERO DE LA PERSONA FÍSICA Ó DEL REPRESENTANTE LEGAL DE LA EMPRESA",
                    name: "GÉNERO_PERSONA",
                    options: [
                        "FEMENINO", "MASCULINO", "PREFIERO NO RESPONDER"
                    ]
                },
                {
                    idQ: 4,
                    type: "text",
                    text: "RFC: REGISTRO FEDERAL DE CONTRIBUYENTES",
                    name: "RFC",
                    //pattern: "^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3}$",
                    autocomplete : "off"
                },
                {
                    idQ: 5,
                    type: "radio",
                    text: "RÉGIMEN FISCAL",
                    description:"EL REGIMEN FISCAL DEBERÁ TOMARSE DE LA CONSTANCIA DE SITUACIÓN FISCAL VIGENTE, SIENDO ESTA NO ANTERIOR DEL 02 DE DICIEMBRE 2022",
                    name: "RÉGIMEN",
                    options: [
                        "PERSONA MORAL", "PERSONA FÍSICA CON ACTIVIDAD EMPRESARIAL", "RÉGIMEN DE INCORPORACIÓN FISCAL (RIF) O RÉGIMEN SIMPLIFICADO DE CONFIANZA (RESICO)", 
                    ]
                },
                {
                    idQ: 6,
                    type: "text",
                    text: "PÁGINA WEB",
                    name: "PÁGINA_WEB",
                    optional:true,
                    autocomplete:"url"
                },
            ]
        },
        {
            id: 2,
            title: "II.- DOMICILIO FISCAL",
            description: "LOS DATOS FISCALES DEBERÁN TOMARSE DE LA CONSTANCIA DE SITUACIÓN FISCAL VIGENTE, SIENDO ESTA NO ANTERIOR DEL 02 DE DICIEMBRE 2022, Y DEBERÁN DE CAPTURARSE TAL COMO APAREZCAN EN DICHO DOCUMENTO",
            questions: [
                {
                    idQ: 7,
                    type: "text",
                    text: "CALLE",
                    name: "CALLE",
                    autocomplete: "address-line1"
                },
                {
                    idQ: 8,
                    type: "text",
                    text: "NÚMERO",
                    name: "NÚMERO",
                    autocomplete: "address-line2"
                },
                {
                    idQ: 9,
                    type: "text",
                    text: "COLONIA",
                    name: "COLONIA",
                    autocomplete: "address-line3"
                },
                {
                    idQ: 10,
                    type: "select",
                    text: "MUNICIPIO",
                    name: "MUNICIPIO",
                    options: [
                        "",
                        "ABASOLO", "AGUALEGUAS",
                        "LOS ALDAMAS",
                        "ALLENDE",
                        "ANÁHUAC",
                        "APODACA",
                        "ARAMBERRI",
                        "BUSTAMANTE",
                        "CADEREYTA JIMÉNEZ",
                        "CARMEN",
                        "CERRALVO",
                        "CIÉNEGA DE FLORES",
                        "CHINA",
                        "DOCTOR ARROYO",
                        "DOCTOR COSS",
                        "DOCTOR GONZÁLEZ",
                        "GALEANA",
                        "GARCÍA",
                        "SAN PEDRO GARZA GARCÍA",
                        "GENERAL BRAVO",
                        "GENERAL ESCOBEDO",
                        "GENERAL TERÁN",
                        "GENERAL TREVIÑO",
                        "GENERAL ZARAGOZA",
                        "GENERAL ZUAZUA",
                        "GUADALUPE",
                        "LOS HERRERAS",
                        "HIGUERAS",
                        "HUALAHUISES",
                        "ITURBIDE",
                        "JUÁREZ",
                        "LAMPAZOS DE NARANJO",
                        "LINARES",
                        "MARÍN",
                        "MELCHOR OCAMPO",
                        "MIER Y NORIEGA",
                        "MINA",
                        "MONTEMORELOS",
                        "MONTERREY",
                        "PARÁS",
                        "PESQUERÍA",
                        "LOS RAMONES",
                        "RAYONES",
                        "SABINAS HIDALGO",
                        "SALINAS VICTORIA",
                        "SAN NICOLÁS DE LOS GARZA",
                        "HIDALGO",
                        "SANTA CATARINA",
                        "SANTIAGO",
                        "VALLECILLO",
                        "VILLALDAMA",
                    ]
                },
                {
                    idQ: 11,
                    type: "number",
                    text: "CÓDIGO POSTAL",
                    name: "CÓDIGO_POSTAL",
                    autocomplete: "postal-code",
                    maxlength: 5,
                    minlength:5
                },
                {
                    idQ: 12,
                    type: "tel",
                    text: "TELÉFONO DE CONTACTO (FIJO O CELULAR)",
                    name: "TELÉFONO_CONTACTO",
                    maxlength: 10,
                    minlength:10
                },
                {
                    idQ: 13,
                    type: "tel",
                    text: "TELÉFONO DE CONTACTO DEL SOLICITANTE O DEL APODERADO DE LA EMPRESA",
                    name: "TELÉFONO_SOLICITANTE",
                    maxlength: 10,
                    minlength:10
                    
                },
                {
                    idQ: 14,
                    type: "email",
                    text: "CORREO ELECTRÓNICO",
                    name: "CORREO_ELECTRÓNICO",
                },
                {
                    idQ: 15,
                    type: "email",
                    text: "CORREO ELECTRÓNICO DEL SOLICITANTE O DEL APODERADO",
                    name: "CORREO_ELECTRÓNICO_SOLICITANTE",
                    autocomplete : "nope"
                },
            ]        
        },
        {
            id: 3,
            title: "III.- DATOS ECONÓMICOS",
            description: "LOS DATOS FISCALES DEBERÁN TOMARSE DE LA CONSTANCIA DE SITUACIÓN FISCAL VIGENTE Y DEBERÁN ESPECIFICARSE TAL COMO APAREZCAN EN DICHO DOCUMENTO            ",
            questions: [
                {
                    idQ: 16,
                    type: "text",
                    text: "ACTIVIDAD ECONÓMICA",
                    name: "ACTIVIDAD_ECONÓMICA",
                    description: "ACTIVIDAD ECONÓMICA, SE REFIERE A LA ACTIVIDAD ECONÓMICA DETALLADA EN LA CONSTANCIA DE SITUACION FISCAL, Y DEBERÁ REGISTRARSE AQUELLA QUE REPRESENTE LA PRINCIPAL ACTIVIDAD PORCENTUALMENTE O LA ACTIVIDAD PREPONDERANTE; LA ACTIVIDAD DEBERÁ TECLEARSE LITERAL COMO APARECE EN LA CONSTANCIA DE SITUACIÓN FISCAL SIN ABREVIACIONES. LOS DATOS FISCALES DEBERÁN TOMARSE DE LA CONSTANCIA DE SITUACIÓN FISCAL VIGENTE, SIENDO ESTA NO ANTERIOR DEL 02 DE DICIEMBRE 2022, Y DEBERÁN DE CAPTURARSE TAL COMO APAREZCAN EN DICHO DOCUMENTO"
                },
                {
                    idQ: 17,
                    type: "number",
                    text: "TOTAL DE EMPLEADOS (ACTUAL)",
                    name: "TOTAL_EMPLEADOS",
                    description:"FAVOR DE CONSIDERAR PERSONAL DEPENDIENTE DEL NEGOCIO U EMPRESA, INDISTINTO DE LA NATURALEZA DE LA RELACION LABORAL (DADOS DE ALTA O NO, PERMANENTES Y EVENTUALES, ETC)."
                },
                {
                    idQ: 18,
                    type: "number",
                    text: "TOTAL DE EMPLEADOS MUJERES",
                    name: "EMPLEADOS_MUJERES",
                },
                {
                    idQ: 19,
                    type: "number",
                    text: "TOTAL DE EMPLEADOS HOMBRES",
                    name: "EMPLEADOS_HOMBRES",
                },
                {
                    idQ: 20,
                    type: "text",
                    text: "VENTAS ANUALES 2022",
                    name: "VENTAS_2022",
                    description: "CIFRAS EN PESOS, FAVOR DE PROPORCIONAR LAS CIFRAS EXTRAÍDAS DE UN ESTADO FINANCIERO DIRECTO, DE UNA DECLARACIÓN ANUAL PRESENTADA O DE UN CIERRE PRELIMINAR.",
                    money:true
                },
                {
                    idQ: 21,
                    type: "text",
                    text: "% DE EXPORTACIÓN 2022",
                    name: "EXPORTACION_2022",
                    description: "PORCENTAJE DEL TOTAL DE LAS VENTAS QUE FUERON DE EXPORTACION COMO PORCENTAJE DE LOS INGRESOS TOTALES",
                    money:true
                },
                {
                    idQ: 22,
                    type: "text",
                    text: "VENTAS ANUALES 2021",
                    name: "VENTAS_2021",
                    description: "CIFRAS EN PESOS, FAVOR DE PROPORCIONAR LAS CIFRAS EXTRAÍDAS DE UN ESTADO FINANCIERO DIRECTO, DE UNA DECLARACIÓN ANUAL PRESENTADA O DE UN CIERRE PRELIMINAR.",
                    money:true
                },
                {
                    idQ: 23,
                    type: "text",
                    text: "% DE EXPORTACIÓN 2021",
                    name: "EXPORTACION_2021",
                    description: "PORCENTAJE DEL TOTAL DE LAS VENTAS QUE FUERON DE EXPORTACION COMO PORCENTAJE DE LOS INGRESOS TOTALES",
                    money:true
                },
                {
                    idQ: 24,
                    type: "text",
                    text: "VENTAS ANUALES 2020",
                    name: "VENTAS_2020",
                    description: "CIFRAS EN PESOS, FAVOR DE PROPORCIONAR LAS CIFRAS EXTRAÍDAS DE UN ESTADO FINANCIERO DIRECTO, DE UNA DECLARACIÓN ANUAL PRESENTADA O DE UN CIERRE PRELIMINAR.",
                    money:true
                },
                {
                    idQ: 25,
                    type: "text",
                    text: "% DE EXPORTACIÓN 2020",
                    name: "EXPORTACION_2020",
                    description: "PORCENTAJE DEL TOTAL DE LAS VENTAS QUE FUERON DE EXPORTACION COMO PORCENTAJE DE LOS INGRESOS TOTALES",
                    money:true
                },
                {
                    idQ: 26,
                    type: "checkbox",
                    text: "MERCADOS DE EXPORTACIÓN",
                    description:"PRINCIPALES MERCADOS DE EXPORTACIÓN",
                    name: "MERCADOS_DE_EXPORTACIÓN",
                    options: [
                        "ESTADOS UNIDO", 
                        "CANADA", 
                        "CENTRO AMERICA",
                        "CARIBE", 
                        "SUDAMERICA", 
                        "EUROPA", 
                        "MEDIO ORIENTE", 
                        "AFRICA", 
                        "CHINA", 
                        "JAPON",
                        "RESTO DE ASIA", 
                        "OCEANIA",
                        "OTROS"
                    ]
                },
                {
                    idQ: 27,
                    type: "radio",
                    text: "DESTINO DEL CRÉDITO",
                    name: "DESTINO_CRÉDITO",
                    options: [
                        "CAPITAL DE TRABAJO", 
                        "EQUIPAMIENTO"
                    ]
                },
                {
                    idQ: 28,
                    type: "radio",
                    text: "EL LOCAL ES",
                    name: "LOCAL",
                    description: "LOCAL, SE REFIERE AL DOMICILIO FISCAL",
                    options: [
                        "RENTADO",
                        "PROPIO",
                        "PRESTADO"
                    ]
                },
                {
                    idQ: 29,
                    type: "textarea",
                    text: "BREVE DESCRIPCIÓN DE LAS NECESIDADES FINANCIERAS DE LA EMPRESA",
                    name: "NECESIDADES_FINANCIERAS",
                    description: "ESPECIFICAR PARA QUE UTILIZARÁN PRINCIPALMENTE EL RECURSO (PAGO DE SUELDOS, RENTA, PROVEEDORES, COMPRA DE MAQUINARIA Y EQUIPO, COMPRA DE INVENTARIOS, ETC; TODO EN MAYÚSCULAS SIN ACENTOS)"
                },
                {
                    idQ: 30,
                    type: "radio",
                    text: "BANCO SOLICITADO POR LA EMPRESA",
                    name: "BANCO",
                    options: [
                        "AFIRME",
                        "BANBAJIO",
                        "BANORTE",
                        "BANREGIO",
                        "BBVA",
                        "CITIBANAMEX",
                        "HSBC",
                        "MIFEL",
                        "MULTIVA",
                    ]
                },
                {
                    idQ: 31,
                    type: "text",
                    text: "MONTO DEL CRÉDITO SOLICITADO",
                    name: "MONTO_CRÉDITO",
                    description: "EL MONTO NO PODRÁ SER MAYOR DEL EQUIVALENTE A 3 MESES DE VENTAS (CONSIDERE VENTA PROMEDIO MENSUAL DEL ÚLTIMO AÑO), Y EL MONTO SOLICITADO NO PODRÁ SER SUPERIOR A $5 MILLONES DE PESOS; ESPECIFICARLO EN PESOS Y SIN COMAS NI CENTAVOS",
                    max: 5000000,
                    min: 100000,
                    money:true
                },
            ]        
        }
    ]


