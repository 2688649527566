import React from 'react'
import { Header } from '../components/Header'
import LogoImpulso from '../assets/imgs/logotipo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import authApi from '../api/authApi';
import Swal from 'sweetalert2';

export const RecoverPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const  email  = location.state ? location.state.email : ""

    const [data, setData] = useState({email:"", token:"", password:"" })

    const [send, setSend] = useState(false)


    useEffect(() => {
      if(!email)return 
      setData({...data, email})
    }, [email])
    

    const handleChange =(e: { target: HTMLInputElement; })=> {
        setData({ ...data, [e.target.name]: e.target.value})
    }

    const sendEmail = async(e: React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault()
        try {
            if (data.email) {
                const {data:res} = await authApi.post('/reset', {email:data.email})
                console.log(res)
                if (res &&  !res.error) {
                    Swal.fire("Listo", res.msg, 'success')
                    setSend(true)
                  
                }else{
                    Swal.fire("Error", res.msg, 'error')
                }
            }
        } catch (error:any) {
            if (error.response) {
                Swal.fire("Error", error.response.data.msg, 'error')
            }else{
                Swal.fire("Error", "Error en el servidor", 'error')
            }
        }
    }
    const resetPassword = async(e: React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault()
        try {
            if (data.token && data.password) {
                const {data:res} = await authApi.post('/reset/'+data.token, {email:data.email,password:data.password})
                console.log(res)
                if (res &&  !res.error) {
                    Swal.fire("Listo", res.msg, 'success')
                    navigate('/')                    
                }else{
                    Swal.fire("Error", res.msg, 'error')
                }
            }
        } catch (error:any) {
            if (error.response) {
                Swal.fire("Error", error.response.data.msg, 'error')
            }else{
                Swal.fire("Error", "Error en el servidor", 'error')
            }
        }
    }
    

  return (
    <>
      <Header />
      <div className="auth-container">

        <div className="left">
            <div className="logo">
                <img src={LogoImpulso} alt="IMPULSO" />
            </div>
            <div className="form-action">
                <h1>CAMBIO DE CONTRASEÑA</h1>
                

                
            </div>

        </div>
        <div className="right">
            <div className="form-container">


                {!send ?
                    <form className="login-form" onSubmit={sendEmail} >
                        <div className="form-field">
                            <label htmlFor="reset-email">Correo</label>
                            <input value={data.email} onChange={handleChange} type="email" name="email" id="reset-email" required/>
                        </div>
                        <button  className="btn btn-primary" type="submit">Solicitar</button>
                    </form>
                    :
                    <form className="login-form" onSubmit={resetPassword}>
                        <span>Ingresa el token que se ha enviado a tu correo <b>{data.email}</b></span>
                        <span>Si quieres cambiar la dirección o no recibiste el correo pulsa <button type='button' onClick={()=>setSend(false)} className='btn btn-secundary'>aquí</button> </span>
                        
                        <div className="form-field">
                            <label htmlFor="reset-email">TOKEN</label>
                            <input type="text" onChange={handleChange} name="token" id="reset-token" required/>
                        </div>
                        <div className="form-field">
                            <label htmlFor="reset-password">Nueva contraseña</label>
                            <input type="password" onChange={handleChange} name="password" id="reset-password" required/>
                        </div>
                            <button className="btn btn-primary" type="submit">Cambiar</button>
                    </form>

                
            
                }


                
            </div>


        </div>




    </div>
    </>
  )
}
